<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <!-- <b-link
        class="brand-logo"
        :to="{name:'home'}"
      >
        <vuexy-logo />
      </b-link> -->
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="6"
        xl="8"
        class="d-none d-lg-flex align-items-center login-image-section p-0"
      >
        <!-- <div class="w-100 d-lg-flex align-items-center justify-content-center px-5 my-3 mx-3"> -->
        <b-img
          class="login-image-section__img"
          :src="imgUrl"
          alt="Login V2"
        />
        <div class="login-image-filter">
          <div
            class="d-none d-lg-flex align-items-center p-0 login-slogan-section"
          >
            <b-img
              :src="imgEfide"
              alt="Login V2"
              height="130"
            />
            <div class="login-slogan-section__body my-2">
              <span
                class="login-slogan-section__text"
              > Sabemos que el tiempo que le dedicas a tu negocio es importante;
                por eso te presentamos <i class="login-slogan-section__text--italic">Efide Factoring</i>, un espacio donde podrás realizar el proceso de factoring.</span>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="6"
        xl="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Bienvenido a Efide! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Inicie sesión en su cuenta
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <!-- <b-form-group
                label="Correo electrónico"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="correo electónico"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                    :formatter="formatterRuc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->
              <!-- ruc -->
              <b-form-group
                label="RUC"
                label-for="ruc"
              >
                <validation-provider
                  #default="{ errors }"
                  name="RUC"
                  rules="required|ruc"
                  vid="ruc"
                >
                  <b-form-input
                    id="ruc"
                    v-model="ruc"
                    :state="errors.length > 0 ? false:null"
                    :formatter="formatterRuc"
                    name="ruc"
                    placeholder="20123456789"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Contraseña</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>¿Has olvidado tu contraseña?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Contraseña"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Acceder
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <span>¿Nuevo en nuestra plataforma? </span>
            <b-link :to="{name:'auth-register-v1'}">
              <span>&nbsp;Crea una cuenta</span>
            </b-link>
          </b-card-text> -->

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">
              o
            </div>
          </div>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/efide/Welcome-Efide-newgirl.webp'),
      imgEfide: require('@/assets/images/efide/factoring-Whiteversion.png'),
      // validation rulesimport store from '@/store/index'
      required,
      ruc: '',
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/efide/Welcome-Efide-newgirl.jpg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    formatterRuc(value) {
      return value.replace(/[^0-9]/g, '')
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            // email: this.userEmail,
            ruc: this.ruc,
            password: this.password,
          })
            .then(response => {
              const { user } = response.data
              localStorage.setItem('userData', JSON.stringify(user))
              localStorage.setItem('clientType', user.clientType)
              localStorage.setItem('type', user.type)
              useJwt.setToken(response.data.token)
              useJwt.setRefreshToken(response.data.token)
              const router = getHomeRouteForLoggedInUser(user)
              const main = !user.registerComplete && user.canEdit ? 'Para volver al panel es necesario aceptar la Declaración jurada y Finalizar la edición.' : ' ¡Ahora debes completar tu registro!'
              this.$router.replace(router)
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Bienvenido/a ${user.names}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `Has iniciado sesión correctamente como ${user.role}.\n${user.registerComplete && user.canEdit ? '' : main}`,
                    },
                  },
                  {
                    timeout: 8000,
                  })
                })
            })
            .catch(error => {
              console.log('errores', error.response.data.error)
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
  .login-image-section {
    height: 100%;
  }
    .login-image-section__img {
      object-fit: cover;
      height: 100%;
      max-width: 100%;
      object-position: 85%;
      filter: blur(4px);
    }

  .login-image-filter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 28, 74, 0.7);
  }

  .login-slogan-section {
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }
    .login-slogan-section__body {
      width: 60%;
      text-align: center;
    }
    .login-slogan-section__text {
      font-weight: 400;
      color: #e0e0e0;
      font-size: 17.5px;
    }
    .login-slogan-section__text--italic {
      color: #f8673a;
      font-weight: 600;
    }
</style>
